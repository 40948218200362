import { makeStyles } from '@material-ui/core';
import { DTOTakeoutReceptionTimezone } from 'models';
import React, { FC } from 'react';
import { UseFormikProps } from '../../../general/forms/props/useFormikProps';
import { ReceptionTimezoneForm } from './receptionTimezoneForm';

export type ReceptionTimezonesFormValues = {
  receptionTimezones: DTOTakeoutReceptionTimezone[];
};

type Props = { formik: UseFormikProps<ReceptionTimezonesFormValues> };

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ReceptionTimezonesForm: FC<Props> = ({ formik }) => {
  const classes = useStyles();

  return (
    <form className={classes.wrapper} onSubmit={formik.handleSubmit}>
      {formik.values.receptionTimezones.map((timezone, index) => (
        <ReceptionTimezoneForm
          key={`takeout-reception-timezone-${timezone.dayOfWeek.id}`}
          formik={formik}
          timezone={timezone}
          index={index}
        />
      ))}
    </form>
  );
};
