import { makeStyles, Typography } from '@material-ui/core';
import { DTOTakeoutReceptionTimezone } from 'models';
import React, { FC } from 'react';
import { MESSAGE } from '../../../../constants/takeout/settings';
import { checkInvalidStartEndTime } from '../../../../utils/dateUtil';
import { MymeInput } from '../../../general/forms/inputs/mymeInput';
import { UseFormikProps } from '../../../general/forms/props/useFormikProps';
import { AdminButton } from '../../../general/units/adminButton';
import { ReceptionTimezonesFormValues } from './receptionTimezonesForm';

type Props = {
  formik: UseFormikProps<ReceptionTimezonesFormValues>;
  timezone: DTOTakeoutReceptionTimezone;
  index: number;
};

const useStyles = makeStyles(() => ({
  wrapper: { width: '100%', display: 'flex', justifyContent: 'space-evenly' },
  timezoneWrapper: { display: 'flex', flexDirection: 'column' },
  timezone: { width: '100%', display: 'flex' },
  mealTime: {
    fontSize: '1.2rem',
    fontWeight: 700,
    marginBottom: '1rem',
    borderBottom: '1px #8f8f8f dashed',
    lineHeight: '2rem',
    marginTop: '1rem',
  },
  dayOfWeekText: { fontWeight: 700, marginRight: '0.9rem', paddingTop: '0.5rem' },
  fromToText: { display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '1.3rem', margin: '0 0.5rem' },
  errorMessageText: { color: 'red', fontSize: '0.8rem', minHeight: '1.2rem' },
  input: { margin: 0, minWidth: '6rem' },
  marginRight: { marginRight: '1rem' },
}));

export const ReceptionTimezoneForm: FC<Props> = ({ timezone, formik, index }) => {
  const classes = useStyles();
  const { lunchStartTime, lunchEndTime, dinnerStartTime, dinnerEndTime, dayOfWeek } = timezone;

  const makeOneSideBlankBooleanArray = (start?: string, end?: string) => {
    return [!start && !!end, !!start && !end];
  };

  const resetLunch = () =>
    formik.setValues({
      receptionTimezones: formik.values.receptionTimezones.map((receptionTimezone) =>
        receptionTimezone.dayOfWeek.id === dayOfWeek.id
          ? {
              ...timezone,
              lunchStartTime: undefined,
              lunchEndTime: undefined,
            }
          : receptionTimezone
      ),
    });

  const resetDinner = () =>
    formik.setValues({
      receptionTimezones: formik.values.receptionTimezones.map((receptionTimezone) =>
        receptionTimezone.dayOfWeek.id === dayOfWeek.id
          ? {
              ...timezone,
              dinnerStartTime: undefined,
              dinnerEndTime: undefined,
            }
          : receptionTimezone
      ),
    });

  const [lunchStartTimeBlank, lunchEndTimeBlank] = makeOneSideBlankBooleanArray(lunchStartTime, lunchEndTime);
  const [dinnerStartTimeBlank, dinnerEndTimeBlank] = makeOneSideBlankBooleanArray(dinnerStartTime, dinnerEndTime);

  const isInvalidLunchTimeZone = checkInvalidStartEndTime({
    startTime: lunchStartTime,
    endTime: lunchEndTime,
    containEqual: true,
  });
  const isInvalidDinnerTimeZone = checkInvalidStartEndTime({
    startTime: dinnerStartTime,
    endTime: dinnerEndTime,
    containEqual: true,
  });
  const isInvalidDinnerStartTime = checkInvalidStartEndTime({
    startTime: lunchEndTime,
    endTime: dinnerStartTime,
    containEqual: true,
  });

  const lunchErrorInfo = [
    { isInvaid: lunchStartTimeBlank, message: MESSAGE.VALIDATION.START_BLANK },
    { isInvaid: lunchEndTimeBlank, message: MESSAGE.VALIDATION.END_BLANK },
    { isInvaid: isInvalidLunchTimeZone, message: MESSAGE.VALIDATION.INVALID_END_TIME },
  ];

  const dinnerErrorInfo = [
    { isInvaid: dinnerStartTimeBlank, message: MESSAGE.VALIDATION.START_BLANK },
    { isInvaid: dinnerEndTimeBlank, message: MESSAGE.VALIDATION.END_BLANK },
    { isInvaid: isInvalidDinnerTimeZone, message: MESSAGE.VALIDATION.INVALID_END_TIME },
    { isInvaid: isInvalidDinnerStartTime, message: MESSAGE.VALIDATION.INVALID_DINNER_START_TIME },
  ];

  const makeErrorMessage = (errorInfo: { isInvaid: boolean; message: string }[]) => {
    return errorInfo.find((e) => e.isInvaid)?.message;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.timezoneWrapper}>
        {!index && <Typography className={classes.mealTime}>ランチ</Typography>}
        <div className={classes.timezone}>
          <Typography className={classes.dayOfWeekText}>{timezone.dayOfWeek.name}</Typography>
          <MymeInput
            className={classes.input}
            type="time"
            name={`receptionTimezones[${index}].lunchStartTime`}
            value={lunchStartTime || ''}
            onChange={formik.handleChange}
            error={lunchStartTimeBlank || isInvalidLunchTimeZone}
          />
          <Typography className={classes.fromToText}>～</Typography>
          <MymeInput
            className={`${classes.input} ${classes.marginRight}`}
            type="time"
            name={`receptionTimezones[${index}].lunchEndTime`}
            value={lunchEndTime || ''}
            onChange={formik.handleChange}
            error={lunchEndTimeBlank || isInvalidLunchTimeZone}
          />
          <AdminButton text="リセット" color="negative" onClick={resetLunch} />
        </div>
        <Typography className={classes.errorMessageText}>{makeErrorMessage(lunchErrorInfo)}</Typography>
      </div>
      <div className={classes.timezoneWrapper}>
        {!index && <Typography className={classes.mealTime}>ディナー</Typography>}
        <div className={classes.timezone}>
          <Typography className={classes.dayOfWeekText}>{timezone.dayOfWeek.name}</Typography>
          <MymeInput
            className={classes.input}
            type="time"
            name={`receptionTimezones[${index}].dinnerStartTime`}
            value={dinnerStartTime || ''}
            onChange={formik.handleChange}
            error={dinnerStartTimeBlank || isInvalidDinnerTimeZone || isInvalidDinnerStartTime}
          />
          <Typography className={classes.fromToText}>～</Typography>
          <MymeInput
            className={`${classes.input} ${classes.marginRight}`}
            type="time"
            name={`receptionTimezones[${index}].dinnerEndTime`}
            value={dinnerEndTime || ''}
            onChange={formik.handleChange}
            error={dinnerEndTimeBlank || isInvalidDinnerTimeZone}
          />
          <AdminButton text="リセット" color="negative" onClick={resetDinner} />
        </div>
        <Typography className={classes.errorMessageText}>{makeErrorMessage(dinnerErrorInfo)}</Typography>
      </div>
    </div>
  );
};
