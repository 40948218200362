import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { BasicInformationSettingsLayout } from '../../../components/takeout/layouts/basicInformationSettingsLayout';
import { takeoutTheme } from '../../../components/takeout/theme/takeoutTheme';
import { BufferContextProvider } from '../../../modules/general/buffer';
import { SocketContextProvider } from '../../../modules/general/socket';
import { UIContextProvider } from '../../../modules/general/ui';
import { ShopContextProvider } from '../../../modules/takeout/shop';
import { TakeoutOrderContextProvider } from '../../../modules/takeout/takeoutOrder';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const BasicInformationSettingsPage: FC = () => {
  const classes = useStyles();

  return (
    <UIContextProvider>
      <SocketContextProvider>
        <BufferContextProvider>
          <TakeoutOrderContextProvider>
            <ShopContextProvider>
              <ThemeProvider theme={takeoutTheme}>
                <div className={classes.wrapper}>
                  <BasicInformationSettingsLayout />
                </div>
              </ThemeProvider>
            </ShopContextProvider>
          </TakeoutOrderContextProvider>
        </BufferContextProvider>
      </SocketContextProvider>
    </UIContextProvider>
  );
};

export default BasicInformationSettingsPage;
