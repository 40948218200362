import { makeStyles } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { BASIC_INFORMATION } from '../../../constants/takeout/settings';
import { shopActions, ShopContext } from '../../../modules/takeout/shop';
import { ClassName } from '../../general/props/classname';
import { SelectBasicInformationSettingsButton } from './selectBasicInformationSettingsButton';

type Props = ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '8%',
    '@media (max-width: 1024px)': {
      height: '10%',
    },
  },
  grid: {
    display: 'grid',
    // TODO: 店舗情報設定機能の実装時にrepeat(3, 1fr)に変更する
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '5px',
    height: '100%',
  },
}));

export const SelectBasicInformationSettings: FC<Props> = () => {
  const classes = useStyles();

  const shopStore = useContext(ShopContext);

  const selectSetting = (selectingSetting: number) => {
    shopStore.dispatch(shopActions.selectSetting(selectingSetting));
  };

  const basicInformationSettings = [
    BASIC_INFORMATION.PREPARATION_TIME,
    BASIC_INFORMATION.RECEPTION_TIMEZONES,
    // TODO: 店舗情報設定機能の実装時にコメントアウト解除
    // BASIC_INFORMATION.SHOP_INFORMATION,
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.grid}>
        {basicInformationSettings.map((setting) => (
          <SelectBasicInformationSettingsButton
            key={`selecet-basic-information-${setting.title}`}
            title={setting.title}
            value={setting.value}
            onClick={selectSetting}
          />
        ))}
      </div>
    </div>
  );
};
