import { makeStyles, Paper, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { PREPARATION_TIMES } from '../../../constants/takeout/settings';
import { SocketContext } from '../../../modules/general/socket';
import { UIContext } from '../../../modules/general/ui';
import { ShopContext, shopOperations } from '../../../modules/takeout/shop';
import { ClassName } from '../../general/props/classname';
import { PreparationTimeButton } from './preparationTimeButton';

type Props = ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '87%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    '@media (max-width: 1024px)': {
      fontSize: '1.2rem',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '17%',
    width: '100%',
  },
  descriptionWrapper: {
    margin: '0 auto',
  },
  description: {
    lineHeight: '2rem',
    textAlign: 'center',
    fontSize: '1rem',
    '@media (max-width: 896px)': {
      lineHeight: '1rem',
      fontSize: '0.8rem',
    },
  },
}));

export const PreparationTimeSetting: FC<Props> = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const shopStore = useContext(ShopContext);
  const socketStore = useContext(SocketContext);

  const onClick = (preparationTime: number) => {
    shopOperations.updatePreparationTime(uiStore.dispatch, shopStore.dispatch, {
      shopId: shopStore.state.shop.takeoutSetting.shopId,
      preparationTime,
      preparationTimeLinkage: (changedPreparationTime: number) => {
        if (socketStore.state.socket) socketStore.state.socket.emit('preparation time', changedPreparationTime);
      },
    });
  };

  return (
    <Paper className={classes.wrapper}>
      <Typography className={classes.title}>商品準備時間を選択してください</Typography>
      <div className={classes.buttonGroup}>
        {PREPARATION_TIMES.map((time) => (
          <PreparationTimeButton key={`preparation-time-button-${time}`} preparationTime={time} onClick={onClick} />
        ))}
      </div>
      <div className={classes.descriptionWrapper}>
        <Typography className={classes.description}>
          商品準備時間は、お客様の注文時の最短受取日時に反映されます。
        </Typography>
        <Typography className={classes.description}>
          最短受取日時は、受取時間選択時の時間に商品準備時間を足した日時です。
        </Typography>
        <Typography className={classes.description}>
          お客様は最短受取日時以降の営業時間内を受取日時に指定できます。
        </Typography>
      </div>
    </Paper>
  );
};
