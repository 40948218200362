import { makeStyles } from '@material-ui/core';
import { DTOTakeoutOrder } from 'models';
import React, { FC, useContext, useMemo } from 'react';
import { useMountEffect } from '../../../hooks/useMountEffect';
import { useNewOrder } from '../../../hooks/useNewOrder';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { UIContext } from '../../../modules/general/ui';
import { ShopContext, shopOperations } from '../../../modules/takeout/shop';
import {
  takeoutOrderActions,
  TakeoutOrderContext,
  takeoutOrderOperations,
} from '../../../modules/takeout/takeoutOrder';
import { toDatetimeStrFromDate, toNthLateMidnight } from '../../../utils/dateUtil';
import { UiMessage } from '../../general/parts/uiMessage';
import { IconSideMenu } from '../units/iconSideMenu';
import { BasicInformationSettingsMain } from './basicInformationSettingsMain';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: '#F8F8F8',
  },
  iconSideMenu: {
    width: '14rem',
    '@media (max-width: 1024px)': {
      width: 'max-content',
    },
  },
}));

export const BasicInformationSettingsLayout: FC = () => {
  const classes = useStyles();
  const uiStore = useContext(UIContext);
  const authStore = useContext(AuthContext);
  const shopStore = useContext(ShopContext);
  const takeoutOrderStore = useContext(TakeoutOrderContext);

  const fetchTakeoutOrder = () => {
    const today = new Date();
    takeoutOrderOperations.getTakeoutOrder(uiStore.dispatch, takeoutOrderStore.dispatch, {
      // FIXME: shopIdをstoreから取得する
      shopId: 1,
      // 今日のオーダーのみ取得(今日の00時00分から1日後の00時00分までのデータを取得する)
      termStart: toDatetimeStrFromDate(toNthLateMidnight(today)),
      termEnd: toDatetimeStrFromDate(toNthLateMidnight(today, 1)),
    });
  };

  useMountEffect(() => {
    authOperations.checkLogin(authStore.dispatch);
    // FIXME: idをstoreから取得する
    shopOperations.getShop(uiStore.dispatch, shopStore.dispatch, { id: 1 });
    fetchTakeoutOrder();
  });

  const updateTakeoutOrder = (order: DTOTakeoutOrder) => {
    takeoutOrderStore.dispatch(takeoutOrderActions.addTakeoutOrder(order));
  };

  // 新しい注文が入った時の処理
  useNewOrder(updateTakeoutOrder);

  // 本日の新着件数
  const newOrderCount = useMemo(() => takeoutOrderStore.state.todayOrders.filter((e) => e.status === '新着').length, [
    takeoutOrderStore.state.todayOrders,
  ]);

  if (!authStore.state.user) <></>;

  return (
    <div className={classes.wrapper}>
      <IconSideMenu className={classes.iconSideMenu} newOrderCount={newOrderCount} />
      <BasicInformationSettingsMain />
      <UiMessage />
    </div>
  );
};
