import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { ShopContext } from '../../../modules/takeout/shop';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  title: string;
  value: number;
  onClick: (value: number) => void;
};

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2rem 2rem 0 0',
    color: theme.palette.text.primary,
    background: '#e1e1e1',
    '&:hover': {
      backgroundColor: theme.iconSideMenu?.hover.background,
    },
    '@media (max-width: 1024px)': {
      borderRadius: '1.5rem 1.5rem 0 0',
    },
  },
  currentSetting: {
    backgroundColor: theme.iconSideMenu?.hover.background,
  },
  responsiveText: {
    fontSize: '1rem',
    '@media (max-width: 896px)': {
      fontSize: '0.8rem',
    },
  },
}));

export const SelectBasicInformationSettingsButton: FC<Props> = ({ title, value, onClick }) => {
  const classes = useStyles();

  const shopStore = useContext(ShopContext);

  const handleClick = () => {
    onClick(value);
  };

  return (
    <ButtonBase
      className={`${classes.content} ${shopStore.state.selectingSetting === value && classes.currentSetting}`}
      onClick={handleClick}
    >
      <Typography className={classes.responsiveText}>{title}</Typography>
    </ButtonBase>
  );
};
