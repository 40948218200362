import { makeStyles, Paper, Typography } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { DAYS_OF_THE_WEEK } from '../../../constants/takeout/settings';
import { UIContext } from '../../../modules/general/ui';
import { ShopContext, shopOperations } from '../../../modules/takeout/shop';
import { checkInvalidStartEndTime } from '../../../utils/dateUtil';
import { ClassName } from '../../general/props/classname';
import { AdminButton } from '../../general/units/adminButton';
import useReceptionTimezonesFormik from '../forms/formik/useReceptionTimezonesFormik';
import { ReceptionTimezonesForm } from '../forms/forms/receptionTimezonesForm';

type Props = ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '87%',
  },
  contents: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    overflowY: 'auto',
    '@media (max-width: 1024px)': {
      justifyContent: 'space-between',
    },
  },
  button: {
    margin: '1rem auto',
    width: '12rem',
    height: '2.8rem',
    '@media (max-width: 1024px)': {
      margin: '0.5rem auto',
    },
  },
}));

export const ReceptionTimezonesSetting: FC<Props> = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const shopStore = useContext(ShopContext);

  const receptionTimezones = DAYS_OF_THE_WEEK.map((week) => {
    const found = shopStore.state.shop.takeoutReceptionTimezones.find((e) => e.dayOfWeek.id === week.value);

    // NOTE: idは使わないので1を入れている。DB構造修正時にここも変更する
    return found || { id: 1, dayOfWeek: { id: week.value, name: week.name } };
  });

  const formik = useReceptionTimezonesFormik({ receptionTimezones }, (values) => {
    const inputReceptionTimezones = values.receptionTimezones
      // 定休日除去
      .filter(({ lunchStartTime, lunchEndTime, dinnerStartTime, dinnerEndTime }) => {
        return !(!lunchStartTime && !lunchEndTime && !dinnerStartTime && !dinnerEndTime);
      })
      // 空文字除去
      .map((timezone) => {
        const { lunchStartTime, lunchEndTime, dinnerStartTime, dinnerEndTime } = timezone;
        return {
          ...timezone,
          lunchStartTime: lunchStartTime || undefined,
          lunchEndTime: lunchEndTime || undefined,
          dinnerStartTime: dinnerStartTime || undefined,
          dinnerEndTime: dinnerEndTime || undefined,
        };
      });

    shopOperations.updateReceptionTimezones(uiStore.dispatch, shopStore.dispatch, {
      shopId: shopStore.state.shop.takeoutSetting.shopId,
      receptionTimezones: inputReceptionTimezones,
    });
  });

  // 確定ボタン押下可否用
  const [canNotSubmit, setCanNotSubmit] = useState(false);
  useEffect(() => {
    setCanNotSubmit(
      formik.values.receptionTimezones
        .map(
          ({ lunchStartTime, lunchEndTime, dinnerStartTime, dinnerEndTime }) =>
            // 一方しか入力がない
            (!!lunchStartTime && !lunchEndTime) ||
            (!lunchStartTime && !!lunchEndTime) ||
            (!!dinnerStartTime && !dinnerEndTime) ||
            (!dinnerStartTime && !!dinnerEndTime) ||
            // 時間が反転(同じも含む)
            checkInvalidStartEndTime({
              startTime: lunchStartTime,
              endTime: lunchEndTime,
              containEqual: true,
            }) ||
            checkInvalidStartEndTime({
              startTime: dinnerStartTime,
              endTime: dinnerEndTime,
              containEqual: true,
            }) ||
            checkInvalidStartEndTime({
              startTime: lunchEndTime,
              endTime: dinnerStartTime,
              containEqual: true,
            })
        )
        // 1つでも該当
        .some((e) => e)
    );
  }, [formik.values]);

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.contents}>
        <ReceptionTimezonesForm formik={formik} />
        <AdminButton
          className={classes.button}
          color="positive"
          text="確定"
          onClick={formik.handleSubmit}
          disabled={canNotSubmit}
        />
      </div>
    </Paper>
  );
};
