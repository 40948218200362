import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { FC, ChangeEvent } from 'react';
import { ClassName } from '../../props/classname';

export type MymeInputProps = {
  id?: string;
  type: 'text' | 'textarea' | 'date' | 'time' | 'password' | 'email' | 'tel' | 'postal-code' | 'number';
  name: string;
  label?: string;
  value: string | number;
  width?: CSSProperties['width'];
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
} & ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '0.5rem',
  },
}));

export const MymeInput: FC<MymeInputProps> = ({
  id,
  type,
  name,
  label,
  value,
  width = 'auto',
  placeholder = '',
  disabled = false,
  error = false,
  helperText = '',
  onChange,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      className={`${classes.wrapper} ${className}`}
      type={type}
      color="primary"
      variant="outlined"
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: { width },
      }}
      name={name}
      label={label}
      value={value}
      placeholder={placeholder}
      multiline={type === 'textarea'}
      rows={type === 'textarea' ? 5 : 1}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onChange={onChange}
    />
  );
};
