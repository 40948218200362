import { useFormik } from 'formik';
import { UseFormikProps } from '../../../general/forms/props/useFormikProps';
import { ReceptionTimezonesFormValues } from '../forms/receptionTimezonesForm';

const useReceptionTimezonesFormik = (
  initialValues: ReceptionTimezonesFormValues,
  onSubmit: (values: ReceptionTimezonesFormValues) => void
): UseFormikProps<ReceptionTimezonesFormValues> => {
  return useFormik({
    initialValues,
    onSubmit,
  });
};

export default useReceptionTimezonesFormik;
