import { makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionsMenuButton } from '../../general/parts/functionsMenuButton';
import { ClassName } from '../../general/props/classname';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '10%',
    width: '95%',
    display: 'flex',
  },
  title: {
    width: '95%',
    textAlign: 'center',
    fontSize: '2rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontWeight: 700,
    '@media (max-width: 1024px)': {
      fontSize: '1.5rem',
    },
  },
  toggleMenuWrapper: {
    width: '5%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export const BasicInformationSettingsHeader: FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>テイクアウト基本設定</Typography>
      <FunctionsMenuButton className={classes.toggleMenuWrapper} />
    </div>
  );
};
