import { makeStyles } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { BASIC_INFORMATION } from '../../../constants/takeout/settings';
import { ShopContext } from '../../../modules/takeout/shop';
import { ClassName } from '../../general/props/classname';
import { PreparationTimeSetting } from '../parts/preparationTimeSetting';
import { ReceptionTimezonesSetting } from '../parts/receptionTimezonesSetting';
import { SelectBasicInformationSettings } from '../parts/selectBasicInformationSettings';

type Props = ClassName;

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '95%',
    height: '85%',
  },
}));

export const BasicInformationSettings: FC<Props> = () => {
  const classes = useStyles();

  const shopStore = useContext(ShopContext);
  const { selectingSetting } = shopStore.state;

  return (
    <div className={classes.wrapper}>
      <SelectBasicInformationSettings />
      {selectingSetting === BASIC_INFORMATION.PREPARATION_TIME.value && <PreparationTimeSetting />}
      {selectingSetting === BASIC_INFORMATION.RECEPTION_TIMEZONES.value && <ReceptionTimezonesSetting />}
      {selectingSetting === BASIC_INFORMATION.SHOP_INFORMATION.value && <div>{/* 店舗情報設定をここに追加 */}</div>}
    </div>
  );
};
