import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { ShopContext } from '../../../modules/takeout/shop';

interface Props {
  preparationTime: number;
  onClick: (preparationTime: number) => void;
}

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    width: '13%',
    flexDirection: 'column',
    borderRadius: '2rem 2rem',
    color: theme.palette.text.primary,
    background: '#e1e1e1',
    '&:hover': {
      backgroundColor: theme.iconSideMenu?.hover.background,
    },
  },
  currentPreparationTime: {
    backgroundColor: theme.iconSideMenu?.hover.background,
  },
  preparationTime: {
    fontSize: '1.5rem',
  },
}));

export const PreparationTimeButton: FC<Props> = ({ preparationTime, onClick }) => {
  const classes = useStyles();
  const shopStore = useContext(ShopContext);

  const { preparationTime: preparationTimeState } = shopStore.state.shop.takeoutSetting;

  const handleClick = () => {
    onClick(preparationTime);
  };
  return (
    <ButtonBase
      onClick={handleClick}
      className={`${classes.content} ${preparationTime === preparationTimeState && classes.currentPreparationTime}`}
    >
      <Typography className={classes.preparationTime}>{`${preparationTime}分`}</Typography>
    </ButtonBase>
  );
};
